import React from 'react';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';

import * as styles from './index.module.css';

const SeriesBanner = ({ mainImage, title, lede, backgroundColor }) => {
  return (
    <div
      className={styles['root']}
      style={backgroundColor && { backgroundColor }}
    >
      <div className={styles['mainImageWrapper']}>
        <ObjectFitCoverImage
          src={mainImage.resize.src}
          alt=""
          width={600}
          height={315}
        />
      </div>

      <div className={styles['contentWrapper']}>
        <div className={styles['titleWrapper']}>
          <h1 className={styles['title']}>{title}</h1>
        </div>

        <div>
          <p className={styles['lede']}>{lede.lede}</p>
        </div>
      </div>
    </div>
  );
};

export default SeriesBanner;
