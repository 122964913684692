import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';
import ArticleList from '../../organisms/ArticleList';

import SeriesBanner from '../../molecules/series/SeriesBanner';

import Breadcrumb from '../../molecules/common/Breadcrumb';
import Pagination from '../../molecules/common/Pagination';

import * as styles from './index.module.css';

const SeriesTemplate = ({ pageContext }) => {
  const { pagePath, series, pagination } = pageContext;

  const breadcrumbItems = [
    {
      to: '/',
      label: 'ホーム',
    },
    {
      to: '/series',
      label: 'シリーズ一覧',
    },
    {
      to: `/series/${series.slug}`,
      label: series.title,
      partiallyActive: true,
    },
  ];

  return (
    <PublicRoot>
      <SEO
        path={pagePath}
        title={series.title}
        description={series.description.description}
        ogImage={
          series.ogpImage
            ? series.ogpImage.resize.src
            : series.mainImage.resize.src
        }
      />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <nav className={styles['nav']}>
              <ContainerDefault>
                <Breadcrumb items={breadcrumbItems} />
              </ContainerDefault>
            </nav>

            <section className={styles['section']}>
              <ContainerDefault>
                <div className={styles['bannerWrapper']}>
                  <SeriesBanner
                    mainImage={series.mainImage}
                    title={series.title}
                    lede={series.lede}
                    backgroundColor={series.backgroundColor}
                  />
                </div>

                <div className={styles['articleListWrapper']}>
                  <ArticleList articles={series.articles} />
                </div>

                {pagination.pageCount > 1 && (
                  <div className={styles['paginationWrapper']}>
                    <Pagination
                      pagination={pagination}
                      basePath={`/series/${series.slug}`}
                    />
                  </div>
                )}
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default SeriesTemplate;
